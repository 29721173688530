import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
// import Hotkeys from "./Hotkeys"
import classNames from "classnames"
import { initialFetch } from "../reducers/rootReducer"
import VoiceList from "./VoiceList"
import VoicePlayer from "./VoicePlayer"
import Filters from "./Filters"

export default function FileBrowser({}) {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    dispatch(initialFetch()).then(() => setLoaded(true))
  }, [])

  return (
    <div className={classNames("flex flex-col w-full", {})}>
      {loaded && (
        <>
          {/* <Hotkeys /> */}
          <div className="sticky top-0 flex w-full z-10 border-b border-black">
            <Filters />
            <VoicePlayer />
          </div>

          <VoiceList />
        </>
      )}
    </div>
  )
}
