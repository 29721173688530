import api from "../api"

const initialState = {
  voices: [],
  count: 0,
  index: 0,
  selectionStart: 0,
  selectionEnd: 0,
  voice: null,
  filters: {},
  playing: false,
}

export default function rootReducer(state = initialState, action) {
  console.log(action.type)
  const payload = action.payload || {}
  const oldState = state
  state = { ...state, ...payload }
  if (action.type === "next") {
    state.index = state.index + (1 % state.voices.length)
  } else if (action.type === "prev") {
    state.index = Math.max(state.index - 1, 0)
  }
  if (state.selectionEnd === null) {
    state.selectionEnd = state.selectionStart
  }
  if (state.index !== oldState.index) {
    state.voice = state.voices[state.index]
  }
  return state
}

export function initialFetch() {
  return (dispatch, getStore) => {
    return api()
      .GET(`/voices/get_voices`)
      .then(({ voices, filters, count }) => {
        let voice = getStore().voice
        let index = voices.findIndex((v) => v.id === voice?.id)
        let keepPlaying = true
        if (!voice || index < 0) {
          keepPlaying = false
          index = 0
          voice = voices[0]
        }
        dispatch({
          type: "initialFetch",
          payload: {
            voices,
            filters,
            count,
            index,
            selectionStart: 0,
            selectionEnd: 0,
            voice,
            playing: keepPlaying,
          },
        })
      })
  }
}

let loading = false

export function fetchMore() {
  return async (dispatch, getStore) => {
    if (loading) return
    loading = true
    return api()
      .GET(`/voices/get_voices`, { offset: getStore().voices.length, limit: 500 })
      .then(({ voices, count }) => {
        dispatch({
          type: "fetchMore",
          payload: {
            voices: [...getStore().voices, ...voices],
            count,
          },
        })
        setTimeout(() => (loading = false), 100)
      })
  }
}

export function updateVoice({ id, rating, todo }) {
  return (dispatch, getStore) => {
    return api()
      .POST(`/voices/update_voice/${id}`, { rating, todo })
      .then((voice) => {
        let voices = getStore().voices
        const index = voices.findIndex((v) => v.id === voice.id)
        voices = [...voices]
        voices[index] = voice
        dispatch({ type: "updateVoice", payload: { voices } })
      })
  }
}

export function setFilters(newFilters) {
  return (dispatch, getStore) => {
    let filters = getStore().filters
    filters = { ...filters, ...newFilters }
    dispatch({ type: "setFilters", payload: { filters } })
  }
}

export function updateFilters() {
  return (dispatch, getStore) => {
    const filters = getStore().filters
    api()
      .POST(`/voices/update_filters`, { filters })
      .then(() => {
        dispatch(initialFetch())
        window.scrollTo(0, 0)
      })
  }
}

export function resetFilters() {
  return (dispatch, getStore) => {
    dispatch({
      type: "setFilters",
      payload: {
        filters: {
          ratings: { "-1": false, 0: true, 1: true, 2: true, 3: true, 4: true, 5: true },
          artist_name: "",
          genders: { female: true, couple: true },
          genres: { オナ声: true, エロ声: true, その他: true, 体験談: true, 私の秘密: true },
          deleteds: { true: true, false: true },
          todos: { "": true, Todo: true, Wait: true, Ignore: true },
          sort_by: "id",
          sort_direction: "DESC",
          duration: null,
          code: "",
        },
      },
    })
  }
}

export function unratedFilters() {
  return (dispatch, getStore) => {
    dispatch({
      type: "setFilters",
      payload: {
        filters: {
          ratings: { "-1": false, 0: true, 1: false, 2: false, 3: false, 4: false, 5: false },
          artist_name: "",
          genders: { female: true, couple: true },
          genres: { オナ声: true, エロ声: true, その他: true },
          deleteds: { true: true, false: true },
          todos: { "": true, Todo: true, Wait: true, Ignore: true },
          sort_by: "sort_order",
          sort_direction: "ASC",
          duration: null,
          code: "",
        },
      },
    })
  }
}

export function deletedFilters() {
  return (dispatch, getStore) => {
    dispatch({
      type: "setFilters",
      payload: {
        filters: {
          ratings: { "-1": false, 0: true, 1: false, 2: false, 3: false, 4: false, 5: false },
          artist_name: "",
          genders: { female: true, couple: true },
          genres: { オナ声: true, エロ声: true, その他: true },
          deleteds: { true: true, false: false },
          todos: { "": true, Todo: true, Wait: true, Ignore: true },
          sort_by: "id",
          sort_direction: "DESC",
          duration: null,
          code: "",
        },
      },
    })
  }
}

export const defaultFilters = {
  ratings: { "-1": false, 0: true, 1: true, 2: true, 3: true, 4: true, 5: true },
  artist_name: "",
  genders: { female: true, couple: true },
  genres: { オナ声: true, エロ声: true, その他: true, 体験談: true, 私の秘密: true },
  deleteds: { true: true, false: true },
  todos: { "": true, Todo: true, Wait: true, Ignore: true },
  sort_by: "id",
  sort_direction: "DESC",
  duration: null,
  code: "",
}

export const allRatings = { "-1": true, 0: true, 1: true, 2: true, 3: true, 4: true, 5: true }
export const allGenres = { オナ声: true, エロ声: true, その他: true, 体験談: true, 私の秘密: true }
export const allTodos = { "": true, Todo: true, Wait: true, Ignore: true }
