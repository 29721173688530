import { useSelector, useDispatch } from "react-redux"
import useScrollLoad from "../hooks/useScrollLoad"
import VoiceListItem from "./VoiceListItem"

export default function VoiceList() {
  const dispatch = useDispatch()
  const voices = useSelector((state) => state.voices)
  const index = useSelector((state) => state.index)
  let selectionStart = useSelector((state) => state.selectionStart)
  let selectionEnd = useSelector((state) => state.selectionEnd)
  if (selectionStart > selectionEnd) [selectionStart, selectionEnd] = [selectionEnd, selectionStart]

  const { containerRef, topIndex, bottomIndex, topHeight, bottomHeight } = useScrollLoad({
    elementCount: voices.length,
    elementHeight: 28,
    top: 156,
  })

  return (
    <div className="flex flex-col w-full" ref={containerRef}>
      <div style={{ height: topHeight }}></div>
      {voices.slice(topIndex, bottomIndex + 1).map((voice, i) => (
        <VoiceListItem
          key={voice.id}
          voice={voice}
          selected={i + topIndex >= selectionStart && i + topIndex <= selectionEnd}
          active={index === i + topIndex}
          index={i + topIndex}
        />
      ))}
      <div style={{ height: bottomHeight }}></div>
    </div>
  )
}
