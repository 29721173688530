import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchMore } from "../reducers/rootReducer"

export default function useScrollLoad({ elementCount, elementHeight, top }) {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const [state, setState] = useState({
    topIndex: 0,
    bottomIndex: 0,
    topHeight: 0,
    bottomHeight: 0,
    topThreshold: 0,
    bottomThreshold: 0,
    count: elementCount,
  })
  const { topIndex, bottomIndex, topHeight, bottomHeight, topThreshold, bottomThreshold, count } = state

  useEffect(() => {
    const handler = (e) => {
      const windowHeight = window.innerHeight
      const containerHeight = containerRef.current.offsetHeight + top
      const scrollY = window.scrollY
      const maxScroll = containerHeight - windowHeight

      if (maxScroll > 0 && maxScroll - scrollY < 1000) {
        dispatch(fetchMore())
      }

      const bottomY = windowHeight + scrollY - top
      const topY = scrollY - top

      // don't load more on every scroll tick, only after we scroll a certain distance
      if (topY > topThreshold && bottomY < bottomThreshold && elementCount === count) return

      let bottomIndex = Math.ceil(bottomY / elementHeight)
      let topIndex = Math.floor(topY / elementHeight)
      bottomIndex = Math.min(elementCount - 1, bottomIndex + 40)
      topIndex = Math.max(0, topIndex - 30)

      setState({
        bottomIndex,
        topIndex,
        bottomHeight: (elementCount - bottomIndex - 1) * elementHeight,
        topHeight: topIndex * elementHeight,
        topThreshold: topY - 200,
        bottomThreshold: bottomY + 200,
        count: elementCount,
      })
      console.log({ topY, bottomY, topThreshold, bottomThreshold })
    }
    document.addEventListener("scroll", handler)
    handler()
    return () => {
      document.removeEventListener("scroll", handler)
    }
  }, [elementCount, elementHeight, top, topThreshold, bottomThreshold])

  return { containerRef, topIndex, bottomIndex, topHeight, bottomHeight }
}
