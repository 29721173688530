import React, { useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"

import { setFilters, updateVoice, defaultFilters, allRatings } from "../reducers/rootReducer"

import { ReactComponent as PlayIcon } from "../images/play.svg"
import { ReactComponent as PauseIcon } from "../images/pause.svg"
import { ReactComponent as StarIcon } from "../images/star.svg"
import { ReactComponent as NoIcon } from "../images/no.svg"
import { ReactComponent as DeleteIcon } from "../images/delete.svg"

function VoiceListItem({ voice, index, selected, active }) {
  const dispatch = useDispatch()

  const playing = useSelector((state) => state.playing)

  const ref = useRef()

  useEffect(() => {
    const handler = (e) => e.shiftKey && e.preventDefault()
    const el = ref.current
    el.addEventListener("mousedown", handler)
    return () => el.removeEventListener("mousedown", handler)
  }, [])

  const duration = `${Math.floor(voice.duration / 60)}:${String(voice.duration % 60).padStart(2, "0")}`
  const rating = voice.rating

  function updateRating(rating) {
    return dispatch(updateVoice({ id: voice.id, rating }))
  }

  return (
    <div
      ref={ref}
      className={classNames("flex items-center w-full group border-b border-black space-x-6 px-2 h-7 text-gray-300", {
        "bg-gray-800": !selected && !active,
        "bg-gray-700": selected && !active,
        "bg-gray-600": active,
        "hover:bg-gray-700": !active,
      })}
      onClick={(e) => {
        if (e.shiftKey) {
          dispatch({ type: "select", payload: { selectionEnd: index } })
        } else {
          dispatch({ type: "select", payload: { selectionStart: index, selectionEnd: index } })
        }
      }}
      onDoubleClick={() => dispatch({ type: "play", payload: { index, playing: true } })}
    >
      {(!playing || !active) && (
        <button
          className="focus:outline-none flex-0"
          onClick={() => dispatch({ type: "play", payload: { index, playing: true } })}
        >
          <PlayIcon
            className={classNames("fill-current w-4 h-4 text-gray-300 group-hover:visible", { invisible: !active })}
          />
        </button>
      )}
      {playing && active && (
        <button
          className="focus:outline-none flex-0"
          onClick={() => dispatch({ type: "pause", payload: { playing: false } })}
        >
          <PauseIcon className={classNames("fill-current w-4 h-4 text-gray-300")} />
        </button>
      )}

      <a
        className={classNames("w-12 truncate text-12 font-medium text-left hover:underline hover:text-gray-300", {
          "text-gray-400": !voice.downloaded,
          "text-gray-300": voice.downloaded,
        })}
        href={`https://koe-koe.com/detail.php?n=${voice.id}`}
        target="_blank"
      >
        {voice.id}
      </a>
      <div className="w-64 truncate text-14 font-medium text-left" title={voice.name}>
        {voice.name}
      </div>
      <button
        className="w-24 truncate text-14 font-medium text-left hover:underline cursor-pointer"
        title={voice.artist_name}
        onClick={() => dispatch(setFilters({ ...defaultFilters, ratings: allRatings, artist_name: voice.artist_name }))}
      >
        {voice.artist_name}
      </button>
      <button
        className="w-24 truncate text-12 font-medium text-left hover:underline hover:text-gray-300 text-gray-400"
        title={voice.code}
        onClick={() => dispatch(setFilters({ ...defaultFilters, ratings: allRatings, code: voice.code }))}
      >
        {voice.code}
      </button>
      <div className="w-12 truncate text-12 font-medium text-left text-gray-400">{voice.genre}</div>
      <div className="w-12 truncate text-12 font-medium text-left text-gray-400">{voice.gender}</div>
      <button
        className="w-12 truncate text-12 font-medium text-left hover:underline hover:text-gray-300 text-gray-400"
        onClick={() => dispatch(setFilters({ ...defaultFilters, ratings: allRatings, duration: voice.duration }))}
      >
        {duration}
      </button>
      <div className="w-20 truncate text-12 font-medium text-left text-gray-400">{voice.posted_at}</div>
      <div className="w-18 truncate text-12 font-medium text-left text-gray-400">{voice.likes} likes</div>
      <div className="flex flex-0 pr-1">
        <button
          className={classNames("focus:outline-none mr-1 hover:text-red-800", {
            "invisible text-gray-500 group-hover:visible": rating >= 0,
            "text-red-800": rating < 0,
          })}
          onClick={() => updateRating(-1).then(() => active && dispatch({ type: "next" }))}
        >
          <DeleteIcon className={classNames("fill-current h-3 w-3")} />
        </button>
        <button
          className="focus:outline-none mr-1 invisible group-hover:visible text-gray-500 hover:text-gray-400"
          onClick={() => updateRating(0)}
        >
          <NoIcon className={classNames("fill-current h-3 w-3")} />
        </button>
        <div
          className={classNames("flex hover:text-gray-400", {
            "text-gray-300": rating >= 1,
            "text-gray-500 invisible group-hover:visible": rating < 1,
          })}
        >
          <button className="focus:outline-none mr-1px" onClick={() => updateRating(1)}>
            <StarIcon className={classNames("fill-current h-3 w-3")} />
          </button>
          <div
            className={classNames("flex hover:text-gray-400", {
              "text-gray-300": rating >= 2,
              "text-gray-500 invisible group-hover:visible": rating < 2,
            })}
          >
            <button className="focus:outline-none mr-1px" onClick={() => updateRating(2)}>
              <StarIcon className={classNames("fill-current h-3 w-3")} />
            </button>
            <div
              className={classNames("flex hover:text-gray-400", {
                "text-gray-300": rating >= 3,
                "text-gray-500 invisible group-hover:visible": rating < 3,
              })}
            >
              <button className="focus:outline-none mr-1px" onClick={() => updateRating(3)}>
                <StarIcon className={classNames("fill-current h-3 w-3")} />
              </button>
              <div
                className={classNames("flex hover:text-gray-400", {
                  "text-gray-300": rating >= 4,
                  "text-gray-500 invisible group-hover:visible": rating < 4,
                })}
              >
                <button className="focus:outline-none mr-1px" onClick={() => updateRating(4)}>
                  <StarIcon className={classNames("fill-current h-3 w-3")} />
                </button>
                <div
                  className={classNames("flex hover:text-gray-400", {
                    "text-gray-300": rating >= 5,
                    "text-gray-500 invisible group-hover:visible": rating < 5,
                  })}
                >
                  <button className="focus:outline-none mr-1px" onClick={() => updateRating(5)}>
                    <StarIcon className={classNames("fill-current h-3 w-3")} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={classNames("w-14 text-12 font-medium text-left hover:underline text-gray-300", {
          "invisible group-hover:visible": !voice.todo,
        })}
        onClick={() =>
          dispatch(updateVoice({ id: voice.id, todo: todoValues[(todoValues.indexOf(voice.todo) + 1) % 4] }))
        }
      >
        {voice.todo || "None"}
      </button>
      <div className="w-14 text-12 font-medium text-left text-gray-400">{voice.deleted ? "(Deleted)" : ""}</div>
    </div>
  )
}

const todoValues = ["", "Todo", "Wait", "Ignore"]

export default React.memo(VoiceListItem)
