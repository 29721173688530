import { useSelector, useDispatch } from "react-redux"
import { updateFilters, setFilters, resetFilters, deletedFilters, unratedFilters } from "../reducers/rootReducer"

export default function Filters() {
  const dispatch = useDispatch()

  const filters = useSelector((state) => state.filters)
  const ratings = filters.ratings || {}
  const artistName = filters.artist_name || ""
  const genres = filters.genres || {}
  const genders = filters.genders || {}
  const sortBy = filters.sort_by || "sort_order"
  const sortDirection = filters.sort_direction || "ASC"
  const deleteds = filters.deleteds || {}
  const todos = filters.todos || {}
  const code = filters.code || ""

  return (
    <div className="flex flex-1 bg-gray-700 text-gray-300 px-4 py-2 space-x-6">
      <div className="flex flex-col">
        <div className="text-14 font-medium text-left mb-2">Rating</div>
        {[-1, 0, 1, 2, 3, 4, 5].map((i) => (
          <label className="flex items-center" key={i}>
            <input
              type="checkbox"
              checked={ratings[i] !== false}
              onChange={() => dispatch(setFilters({ ratings: { ...ratings, [i]: ratings[i] === false } }))}
            />
            <span className="ml-1 text-12 w-3 text-right">{i}</span>
          </label>
        ))}
      </div>

      <div className="flex flex-col">
        <label className="text-14 font-medium mb-2 text-left">Genre</label>
        {["オナ声", "エロ声", "その他", "体験談", "私の秘密"].map((genre) => (
          <label className="flex items-center" key={genre}>
            <input
              type="checkbox"
              checked={genres[genre] !== false}
              onChange={() => dispatch(setFilters({ genres: { ...genres, [genre]: genres[genre] === false } }))}
            />
            <span className="ml-1 text-12 w-3 text-right whitespace-nowrap">{genre}</span>
          </label>
        ))}
      </div>

      <div className="flex flex-col">
        <label className="text-14 font-medium mb-2 text-left">Gender</label>
        {["female", "couple"].map((gender) => (
          <label className="flex items-center" key={gender}>
            <input
              type="checkbox"
              checked={genders[gender] !== false}
              onChange={() => dispatch(setFilters({ genders: { ...genders, [gender]: genders[gender] === false } }))}
            />
            <span className="ml-1 text-12 w-3 text-right">{gender}</span>
          </label>
        ))}
      </div>

      <div className="flex flex-col">
        <label className="text-14 font-medium mb-2 text-left">Todo</label>
        {["", "Todo", "Wait", "Ignore"].map((value) => (
          <label className="flex items-center" key={value}>
            <input
              type="checkbox"
              checked={todos[value] !== false}
              onChange={() => dispatch(setFilters({ todos: { ...todos, [value]: todos[value] === false } }))}
            />
            <span className="ml-1 text-12 w-3 text-right">{value || "None"}</span>
          </label>
        ))}
      </div>

      <div className="flex flex-col">
        <label className="text-14 font-medium mb-2 text-left">Deleted</label>
        {["true", "false"].map((value) => (
          <label className="flex items-center" key={value}>
            <input
              type="checkbox"
              checked={deleteds[value] !== false}
              onChange={() => dispatch(setFilters({ deleteds: { ...deleteds, [value]: deleteds[value] === false } }))}
            />
            <span className="ml-1 text-12 w-3 text-right">{value}</span>
          </label>
        ))}
      </div>

      <div className="flex flex-col">
        <label className="text-14 font-medium mb-2 text-left">Sort By</label>
        <select
          className="w-24 text-black"
          value={sortBy}
          onChange={(e) => dispatch(setFilters({ sort_by: e.target.value }))}
        >
          <option value="sort_order">Shuffled</option>
          <option value="id">ID</option>
          <option value="artist_name">Artist</option>
          <option value="duration">Duration</option>
        </select>
        <label className="text-14 font-medium my-2 text-left">Sort Direction</label>
        <select
          className="w-24 text-black"
          value={sortDirection}
          onChange={(e) => dispatch(setFilters({ sort_direction: e.target.value }))}
        >
          <option value="ASC">Ascending</option>
          <option value="DESC">Descending</option>
        </select>
      </div>

      <div className="flex flex-col">
        <label htmlFor="artistName" className="text-14 font-medium mb-2 text-left">
          Artist Name
        </label>
        <input
          id="artistName"
          className="text-input text-14 w-36 text-black"
          value={artistName}
          onChange={(e) => dispatch(setFilters({ artist_name: e.target.value }))}
        />
        <label htmlFor="artistCode" className="text-14 font-medium my-2 text-left">
          Code
        </label>
        <input
          id="artistCode"
          className="text-input text-14 w-36 text-black"
          value={code}
          onChange={(e) => dispatch(setFilters({ code: e.target.value }))}
        />
      </div>

      <div className="flex flex-1 flex-col justify-end space-y-2">
        <button className="btn" onClick={() => dispatch(unratedFilters())}>
          Unrated Filters
        </button>
        <button className="btn" onClick={() => dispatch(deletedFilters())}>
          Deleted Filters
        </button>
        <button className="btn" onClick={() => dispatch(resetFilters())}>
          Reset Filters
        </button>
        <button className="btn" onClick={() => dispatch(updateFilters())}>
          Apply Filters
        </button>
      </div>
    </div>
  )
}
