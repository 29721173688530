import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import AudioPlayer from "react-h5-audio-player"

export default function VoicePlayer() {
  const dispatch = useDispatch()
  const voice = useSelector((state) => state.voice)
  const playing = useSelector((state) => state.playing)

  const [src, setSrc] = useState()

  const player = useRef()
  useEffect(() => {
    const audio = player.current.audio.current
    try {
      if (playing) {
        audio.play()
      } else {
        audio.pause()
      }
    } catch {}
  }, [playing])

  useEffect(() => {
    setSrc(voice?.src)
  }, [voice?.id])

  return (
    <>
      <AudioPlayer
        ref={player}
        src={src}
        autoPlay={false}
        showSkipControls
        onEnded={() => dispatch({ type: "next" })}
        onClickPrevious={() => dispatch({ type: "prev" })}
        onClickNext={() => dispatch({ type: "next" })}
        onPlay={() => dispatch({ type: "onPlay", payload: { playing: true } })}
        onPause={() => dispatch({ type: "onPause", payload: { playing: false } })}
      />
    </>
  )
}
