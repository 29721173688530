import "./App.css"
import FileBrowser from "./components/FileBrowser"

function App() {
  return (
    <div className="App bg-gray-500 flex items-center justify-center">
      <FileBrowser />
    </div>
  )
}

export default App
