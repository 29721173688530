import React from "react"
import ReactDOM from "react-dom"
import "react-h5-audio-player/lib/styles.css"
import "./index.css"
import App from "./App"
import { Provider } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers/rootReducer"

window.apiRoot = window.localStorage.getItem("apiRoot") || "http://localhost:3004"

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
})

Object.defineProperty(window, "reduxStore", {
  get() {
    return store.getState()
  },
})

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
)
